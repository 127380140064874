.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: opacity 1s;  /* adjust this duration as needed */
  opacity: 0;              /* default to invisible */
}

.background-image.active {
  opacity: 1;  /* full visibility */
}

.background-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}


.background-wrapper {
  z-index: -1;  
}

#root {
  position: static;
  height: 100%;
  width: 100%;
}
